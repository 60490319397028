body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
}

.Nav {
  background: #454545;
  color: #fff;
  width: 225px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Nav + * {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.User {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.UserImage {
  border-radius: 50%;
  margin-right: 10px;
  height: 32px;
}

.text-button {
  font: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  display: inline;
  cursor: pointer;
  font-size: 80%;
  color: white;
}

.ChannelNav a {
  font-size: 90%;
  padding: 8px 20px;
  display: block;
  text-decoration: none;
  color: #fff;
}

.ChannelNav a.active {
  background: hsl(30, 50%, 50%);
}

.Channel {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.ChannelMain {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ChannelInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 20px;
  font-weight: bold;
  border-bottom: solid 1px #ccc;
}

.Topic {
}

.TopicInput {
  font: inherit;
  border: 1px solid transparent;
}

.TopicInput:hover {
  border-color: #ccc;
}

.Messages {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 10px 20px 10px 20px;
  line-height: 1.3;
  overflow: auto;
}

.Day {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.DayLine {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 1px;
  background: #ccc;
}

.DayText {
  font-weight: bold;
  padding: 10px;
  font-size: 80%;
}

.Message {
  margin: 5px 0;
  display: -webkit-flex;
  display: flex;
}

.Message.with-avatar {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
}

.Avatar {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
  background-color: #fefefe;
  background-image: '';
}

.Author {
  margin-left: 8px;
}

.UserName {
  font-weight: bold;
  font-size: 90%;
}

.TimeStamp {
  color: #999;
  font-size: 80%;
}

.MessageContent {
}

.Message.no-avatar {
  margin-left: 38px;
}

.ChatInputBox {
  padding: 0px 20px 20px 20px;
}

.ChatInput {
  box-sizing: border-box;
  font: inherit;
  width: 100%;
  padding: 8px;
  border: solid 2px;
  border-color: #aaa;
  border-radius: 6px;
  outline: none;
}

.ChatInput:focus {
  border-color: #666;
}

.Members {
  padding: 20px;
  border-left: solid 1px #ccc;
  width: 150px;
}

.Member {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MemberStatus {
  margin-right: 5px;
  border-radius: 50%;
  height: 0.66em;
  width: 0.66em;
}

.MemberStatus.online {
  background: hsl(110, 50%, 50%);
}

.MemberStatus.offline {
  background: hsl(10, 0%, 70%);
}

.EndOfMessages {
  text-align: center;
  color: hsl(200, 50%, 50%);
  padding: 5px;
}

.LoadMore {
  text-align: center;
}

.Login {
  text-align: center;
  margin-top: 55px;
}

